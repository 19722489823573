import { SiteContext } from "@organic-return/foundation-gatsby-plugin-drupal/src/components/common/SiteContext"
import { IListingSummary } from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React from "react"
import {
  Check,
  Choose,
  Text,
  DateRange,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { EListingDetailsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingDetails"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { ESellFormLayout } from "@organic-return/foundation-react/src/components/Forms/SellForm"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { FooterLayoutLinks } from "@organic-return/foundation-react/src/components/Footer/FooterLayoutLinks"
import { EContactCardLayout } from "@organic-return/foundation-react/src/components/Contact"
import { ListingLayoutSingleColumnAlt } from "@organic-return/foundation-react/src/components/Listing"
import { EWsrvTransform } from "@organic-return/foundation-react/src/lib"
import { EContactFormLayout } from "@organic-return/foundation-react/src/components/Forms"
import { ISiteMenuItem } from "@organic-return/foundation-gatsby-plugin-drupal/src/lib/utils"
import {CommunityLayoutHeroBox} from "@organic-return/foundation-react/src/components/Community/CommunityLayoutHeroBox"

export const SiteContextProvider: React.FC = ({ children }: any) => {
  const data = useStaticQuery(graphql`
    query SiteContextProviderQuery {
      config: drupalSiteConfiguration {
        rentalSearchPath
        listingsSearchPath
      }
    }
  `)
  const selectStyles = {
    control: (base: object) => ({
      ...base,
      borderColor: "transparent",
      background: "transparent",
      borderBottomColor: "var(--color-composite-text)",
      borderRadius: 0,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-composite-text)",
      textTransform: "uppercase",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-composite-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      textTransform: "uppercase",
    }),
    menu: (base: object) => ({
      ...base,
      zIndex: 12
    }),
  }
  const priceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: '> $50K', value: '50000' },
            { label: '> $100K', value: '100000' },
            { label: '> $150K', value: '150000' },
            { label: '> $200K', value: '200000' },
            { label: '> $250K', value: '250000' },
            { label: '> $300K', value: '300000' },
            { label: '> $350K', value: '350000' },
            { label: '> $400K', value: '400000' },
            { label: '> $450K', value: '450000' },
            { label: '> $500K', value: '500000' },
            { label: '> $550K', value: '550000' },
            { label: '> $600K', value: '600000' },
            { label: '> $650K', value: '650000' },
            { label: '> $700K', value: '700000' },
            { label: '> $750K', value: '750000' },
            { label: '> $800K', value: '800000' },
            { label: '> $850K', value: '850000' },
            { label: '> $900K', value: '900000' },
            { label: '> $950K', value: '950000' },
            { label: '> $1M', value: '1000000' },
            { label: '> $1.1M', value: '1100000' },
            { label: '> $1.2M', value: '1200000' },
            { label: '> $1.3M', value: '1300000' },
            { label: '> $1.4M', value: '1400000' },
            { label: '> $1.5M', value: '1500000' },
            { label: '> $1.6M', value: '1600000' },
            { label: '> $1.7M', value: '1700000' },
            { label: '> $1.8M', value: '1800000' },
            { label: '> $1.9M', value: '1900000' },
            { label: '> $2M', value: '2000000' },
            { label: '> $2.1M', value: '2100000' },
            { label: '> $2.2M', value: '2200000' },
            { label: '> $2.3M', value: '2300000' },
            { label: '> $2.4M', value: '2400000' },
            { label: '> $2.5M', value: '2500000' },
            { label: '> $2.6M', value: '2600000' },
            { label: '> $2.7M', value: '2700000' },
            { label: '> $2.8M', value: '2800000' },
            { label: '> $2.9M', value: '2900000' },
            { label: '> $3M', value: '3000000' },
            { label: "> $5M", value: "5000000" },
            { label: "> $7M", value: "7000000" },
            { label: "> $10M", value: "10000000" },
            { label: "> $15M", value: "15000000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: '< $50K', value: '50000' },
            { label: '< $100K', value: '100000' },
            { label: '< $150K', value: '150000' },
            { label: '< $200K', value: '200000' },
            { label: '< $250K', value: '250000' },
            { label: '< $300K', value: '300000' },
            { label: '< $350K', value: '350000' },
            { label: '< $400K', value: '400000' },
            { label: '< $450K', value: '450000' },
            { label: '< $500K', value: '500000' },
            { label: '< $550K', value: '550000' },
            { label: '< $600K', value: '600000' },
            { label: '< $650K', value: '650000' },
            { label: '< $700K', value: '700000' },
            { label: '< $750K', value: '750000' },
            { label: '< $800K', value: '800000' },
            { label: '< $850K', value: '850000' },
            { label: '< $900K', value: '900000' },
            { label: '< $950K', value: '950000' },
            { label: '< $1M', value: '1000000' },
            { label: '< $1.1M', value: '1100000' },
            { label: '< $1.2M', value: '1200000' },
            { label: '< $1.3M', value: '1300000' },
            { label: '< $1.4M', value: '1400000' },
            { label: '< $1.5M', value: '1500000' },
            { label: '< $1.6M', value: '1600000' },
            { label: '< $1.7M', value: '1700000' },
            { label: '< $1.8M', value: '1800000' },
            { label: '< $1.9M', value: '1900000' },
            { label: '< $2M', value: '2000000' },
            { label: '< $2.1M', value: '2100000' },
            { label: '< $2.2M', value: '2200000' },
            { label: '< $2.3M', value: '2300000' },
            { label: '< $2.4M', value: '2400000' },
            { label: '< $2.5M', value: '2500000' },
            { label: '< $2.6M', value: '2600000' },
            { label: '< $2.7M', value: '2700000' },
            { label: '< $2.8M', value: '2800000' },
            { label: '< $2.9M', value: '2900000' },
            { label: '< $3M', value: '3000000' },
            { label: "< $5M", value: "5000000" },
            { label: "< $7M", value: "7000000" },
            { label: "< $10M", value: "10000000" },
            { label: "< $15M", value: "15000000" },
          ]}
        />
      </>
    )
  }

  const rentalPriceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "> $1k", value: "1000" },
            { label: "> $2k", value: "2000" },
            { label: "> $3k", value: "3000" },
            { label: "> $5k", value: "5000" },
            { label: "> $7k", value: "7000" },
            { label: "> $10k", value: "10000" },
            { label: "> $15k", value: "15000" },
            { label: "> $20k", value: "20000" },
            { label: "> $30k", value: "30000" },
            { label: "> $40k", value: "40000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
      </>
    )
  }

  const bedroomsFilter = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Beds"
          placeholder="Beds"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.beds || 0) >= parseInt(vstr) || false
          }}
          param="beds"
          resolver={arg => arg.beds}
          reactSelectStyles={selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  const bathroomsFilter = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Baths"
          placeholder="Baths"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.baths || 0) >= parseInt(vstr) || false
          }}
          param="baths"
          resolver={arg => arg.baths}
          reactSelectStyles={selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="any"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
        />
        {bedroomsFilter(context)}
        {bathroomsFilter(context)}
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.type}
          sort="count"
          match="one"
          reactSelectStyles={selectStyles}
        />
        {priceFilters(context)}
      </>
    )
  }

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Albion",
              label: "Albion"
            },
            {
              value: "Asotin",
              label: "Asotin"
            },
            {
              value: "Benton City",
              label: "Benton City"
            },
            {
              value: "Beverly",
              label: "Beverly"
            },
            {
              value: "Burbank",
              label: "Burbank"
            },
            {
              value: "Clarkston",
              label: "Clarkston"
            },
            {
              value: "Colfax",
              label: "Colfax"
            },
            {
              value: "College Place",
              label: "College Place"
            },
            {
              value: "Colton",
              label: "Colton"
            },
            {
              value: "Connell",
              label: "Connell"
            }, {
              value: "Dayton",
              label: "Dayton"
            },
            {
              value: "Deer Park",
              label: "Deer Park"
            },
            {
              value: "East Wenatchee",
              label: "East Wenatchee"
            },
            {
              value: "Eltopia",
              label: "Eltopia"
            },
            {
              value: "Endicott",
              label: "Endicott"
            },
            {
              value: "Ephrata",
              label: "Ephrata"
            },
            {
              value: "Farmington",
              label: "Farmington"
            },
            {
              value: "Finley",
              label: "Finley"
            },
            {
              value: "Garfield",
              label: "Garfield"
            },
            {
              value: "Grandview",
              label: "Grandview"
            }, {
              value: "Granger",
              label: "Granger"
            },
            {
              value: "Harrah",
              label: "Harrah"
            },
            {
              value: "Kennewick",
              label: "Kennewick"
            },
            {
              value: "La Crosse",
              label: "La Crosse"
            },
            {
              value: "Latah",
              label: "Latah"
            },
            {
              value: "Lewiston",
              label: "Lewiston"
            },
            {
              value: "Mabton",
              label: "Mabton"
            },
            {
              value: "Malden",
              label: "Malden"
            },
            {
              value: "Mattawa",
              label: "Mattawa"
            },
            {
              value: "Mesa",
              label: "Mesa"
            }, {
              value: "Moses Lake",
              label: "Moses Lake"
            },
            {
              value: "Moxee City",
              label: "Moxee City"
            },
            {
              value: "Naches",
              label: "Naches"
            },
            {
              value: "Oakesdale",
              label: "Oakesdale"
            },
            {
              value: "Oroville",
              label: "Oroville"
            },
            {
              value: "Othello",
              label: "Othello"
            },
            {
              value: "Other",
              label: "Other"
            },
            {
              value: "Outlook",
              label: "Outlook"
            },
            {
              value: "Packwood",
              label: "Packwood"
            },
            {
              value: "Palouse",
              label: "Palouse"
            }, {
              value: "Pasco",
              label: "Pasco"
            },
            {
              value: "Paterson",
              label: "Paterson"
            },
            {
              value: "Plymouth",
              label: "Plymouth"
            },
            {
              value: "Pomeroy",
              label: "Pomeroy"
            },
            {
              value: "Prescot",
              label: "Prescot"
            },
            {
              value: "Prosser",
              label: "Prosser"
            },
            {
              value: "Pullman",
              label: "Pullman"
            },
            {
              value: "Republic",
              label: "Republic"
            },
            {
              value: "Richland",
              label: "Richland"
            },
            {
              value: "Ritzville",
              label: "Ritzville"
            }, {
              value: "Rosalia",
              label: "Rosalia"
            },
            {
              value: "Selah",
              label: "Selah"
            },
            {
              value: "Spangle",
              label: "Spangle"
            },
            {
              value: "St. John",
              label: "St. John"
            },
            {
              value: "Steptoe",
              label: "Steptoe"
            },
            {
              value: "Sunnyside",
              label: "Sunnyside"
            },
            {
              value: "Tekoa",
              label: "Tekoa"
            },
            {
              value: "Toppenish",
              label: "Toppenish"
            },
            {
              value: "Touchet",
              label: "Touchet"
            },
            {
              value: "Uniontown",
              label: "Uniontown"
            }, {
              value: "Walla Walla",
              label: "Walla Walla"
            },
            {
              value: "Wallula",
              label: "Wallula"
            },
            {
              value: "Wapato",
              label: "Wapato"
            },
            {
              value: "Warden",
              label: "Warden"
            },
            {
              value: "Washtucna",
              label: "Washtucna"
            },
            {
              value: "West Richland",
              label: "West Richland"
            },
            {
              value: "White Salmon",
              label: "White Salmon"
            },
            {
              value: "Yakima",
              label: "Yakima"
            },
            {
              value: "Zillah",
              label: "Zillah"
            },
          ]}
          showAllEntriesOption={true}
        />
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "RESIDENTIAL",
              label: "RESIDENTIAL",
            },
            {
              value: "LAND",
              label: "LAND",
            },
            {
              value: "MULTI-FAMILY",
              label: "MULTI-FAMILY",
            },
            {
              value: "FARM & RANCH",
              label: "FARM & RANCH",
            },
          ]}
        />
        {priceFilters(context)}
      </>
    )
  }
  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          reactSelectStyles={selectStyles}
          match="any"
        />
        {priceFilters(context)}
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
      </>
    )
  }
  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.price || 0) - (l0.price || 0)
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.price || 0) - (l1.price || 0)
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sq Ft: High to Low",
            key: "sqftDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sqft || 0) - (l0.sqft || 0)
            },
          },
          {
            label: "Sq Ft: Low to High",
            key: "sqftASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sqft || 0) - (l1.sqft || 0)
            },
          },
          {
            label: "Lot Size: High to Low",
            key: "lotDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.lotacres || 0) - (l0.lotacres || 0)
            },
          },
          {
            label: "Lot Size: Low to High",
            key: "lotASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.lotacres || 0) - (l1.lotacres || 0)
            },
          },
          {
            label: "Last Updated",
            key: "statusTSDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.statusTS || "").localeCompare(l0.statusTS || "")
            },
          },
        ]}
      />
    )
  }
  /**
   * Middle filter for real estate listing search
   */
  const listingMiddleFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="text"
          label="MLS#, City, Zip, Area"
          inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
        <span className="text-composite-text not-italic bold">{"OR"}</span>
        <Check
          context={context}
          label="Our Properties Only"
          inputClassName=" bg-transparent"
          param="ours"
          className="text-composite-text"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.exclusive || false
          }}
        />
        <Check
          context={context}
          label="Upcoming Open House"
          inputClassName=" bg-transparent"
          param="hasOpenHouse"
          className="text-composite-text"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.hasOpenHouse || false
          }}
        />
      </>
    )
  }
  /**
   * Standard filters for global Rental entry listing search.
   */
  const rentalEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Albion",
              label: "Albion"
            },
            {
              value: "Asotin",
              label: "Asotin"
            },
            {
              value: "Benton City",
              label: "Benton City"
            },
            {
              value: "Beverly",
              label: "Beverly"
            },
            {
              value: "Burbank",
              label: "Burbank"
            },
            {
              value: "Clarkston",
              label: "Clarkston"
            },
            {
              value: "Colfax",
              label: "Colfax"
            },
            {
              value: "College Place",
              label: "College Place"
            },
            {
              value: "Colton",
              label: "Colton"
            },
            {
              value: "Connell",
              label: "Connell"
            }, {
              value: "Dayton",
              label: "Dayton"
            },
            {
              value: "Deer Park",
              label: "Deer Park"
            },
            {
              value: "East Wenatchee",
              label: "East Wenatchee"
            },
            {
              value: "Eltopia",
              label: "Eltopia"
            },
            {
              value: "Endicott",
              label: "Endicott"
            },
            {
              value: "Ephrata",
              label: "Ephrata"
            },
            {
              value: "Farmington",
              label: "Farmington"
            },
            {
              value: "Finley",
              label: "Finley"
            },
            {
              value: "Garfield",
              label: "Garfield"
            },
            {
              value: "Grandview",
              label: "Grandview"
            }, {
              value: "Granger",
              label: "Granger"
            },
            {
              value: "Harrah",
              label: "Harrah"
            },
            {
              value: "Kennewick",
              label: "Kennewick"
            },
            {
              value: "La Crosse",
              label: "La Crosse"
            },
            {
              value: "Latah",
              label: "Latah"
            },
            {
              value: "Lewiston",
              label: "Lewiston"
            },
            {
              value: "Mabton",
              label: "Mabton"
            },
            {
              value: "Malden",
              label: "Malden"
            },
            {
              value: "Mattawa",
              label: "Mattawa"
            },
            {
              value: "Mesa",
              label: "Mesa"
            }, {
              value: "Moses Lake",
              label: "Moses Lake"
            },
            {
              value: "Moxee City",
              label: "Moxee City"
            },
            {
              value: "Naches",
              label: "Naches"
            },
            {
              value: "Oakesdale",
              label: "Oakesdale"
            },
            {
              value: "Oroville",
              label: "Oroville"
            },
            {
              value: "Othello",
              label: "Othello"
            },
            {
              value: "Other",
              label: "Other"
            },
            {
              value: "Outlook",
              label: "Outlook"
            },
            {
              value: "Packwood",
              label: "Packwood"
            },
            {
              value: "Palouse",
              label: "Palouse"
            }, {
              value: "Pasco",
              label: "Pasco"
            },
            {
              value: "Paterson",
              label: "Paterson"
            },
            {
              value: "Plymouth",
              label: "Plymouth"
            },
            {
              value: "Pomeroy",
              label: "Pomeroy"
            },
            {
              value: "Prescot",
              label: "Prescot"
            },
            {
              value: "Prosser",
              label: "Prosser"
            },
            {
              value: "Pullman",
              label: "Pullman"
            },
            {
              value: "Republic",
              label: "Republic"
            },
            {
              value: "Richland",
              label: "Richland"
            },
            {
              value: "Ritzville",
              label: "Ritzville"
            }, {
              value: "Rosalia",
              label: "Rosalia"
            },
            {
              value: "Selah",
              label: "Selah"
            },
            {
              value: "Spangle",
              label: "Spangle"
            },
            {
              value: "St. John",
              label: "St. John"
            },
            {
              value: "Steptoe",
              label: "Steptoe"
            },
            {
              value: "Sunnyside",
              label: "Sunnyside"
            },
            {
              value: "Tekoa",
              label: "Tekoa"
            },
            {
              value: "Toppenish",
              label: "Toppenish"
            },
            {
              value: "Touchet",
              label: "Touchet"
            },
            {
              value: "Uniontown",
              label: "Uniontown"
            }, {
              value: "Walla Walla",
              label: "Walla Walla"
            },
            {
              value: "Wallula",
              label: "Wallula"
            },
            {
              value: "Wapato",
              label: "Wapato"
            },
            {
              value: "Warden",
              label: "Warden"
            },
            {
              value: "Washtucna",
              label: "Washtucna"
            },
            {
              value: "West Richland",
              label: "West Richland"
            },
            {
              value: "White Salmon",
              label: "White Salmon"
            },
            {
              value: "Yakima",
              label: "Yakima"
            },
            {
              value: "Zillah",
              label: "Zillah"
            },
          ]}
          showAllEntriesOption={true}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
      </>
    )
  }

  const rentalSearchCheckBoxes = function (context: ISearchContext) {
    return (
      <div className="col-span-2 row-span-2 grid grid-col-2 gap-3">
        <label className="col-span-2 text-uppercase">ADDITIONAL FILTERS</label>
        <Check
          context={context}
          label="Air Conditioning"
          inputClassName=" bg-transparent"
          param="air"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "A/C") !== undefined
          }}
        />
        <Check
          context={context}
          label="Waterfront"
          inputClassName=" bg-transparent"
          param="waterfront"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Waterfront") !== undefined
          }}
        />
        <Check
          context={context}
          label="Linens provided"
          inputClassName=" bg-transparent"
          param="linens"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Linens Provided") !== undefined
          }}
        />
        <Check
          context={context}
          label="Pool"
          inputClassName=" bg-transparent"
          param="pool"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Pool") !== undefined
          }}
        />
      </div>)
  }

  /**
   * Advanced filters for global Rental entry listing search.
   */
  const rentalEntryAdvancedFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          className="col-span-2"
          options={[
            {
              value: "Albion",
              label: "Albion"
            },
            {
              value: "Asotin",
              label: "Asotin"
            },
            {
              value: "Benton City",
              label: "Benton City"
            },
            {
              value: "Beverly",
              label: "Beverly"
            },
            {
              value: "Burbank",
              label: "Burbank"
            },
            {
              value: "Clarkston",
              label: "Clarkston"
            },
            {
              value: "Colfax",
              label: "Colfax"
            },
            {
              value: "College Place",
              label: "College Place"
            },
            {
              value: "Colton",
              label: "Colton"
            },
            {
              value: "Connell",
              label: "Connell"
            }, {
              value: "Dayton",
              label: "Dayton"
            },
            {
              value: "Deer Park",
              label: "Deer Park"
            },
            {
              value: "East Wenatchee",
              label: "East Wenatchee"
            },
            {
              value: "Eltopia",
              label: "Eltopia"
            },
            {
              value: "Endicott",
              label: "Endicott"
            },
            {
              value: "Ephrata",
              label: "Ephrata"
            },
            {
              value: "Farmington",
              label: "Farmington"
            },
            {
              value: "Finley",
              label: "Finley"
            },
            {
              value: "Garfield",
              label: "Garfield"
            },
            {
              value: "Grandview",
              label: "Grandview"
            }, {
              value: "Granger",
              label: "Granger"
            },
            {
              value: "Harrah",
              label: "Harrah"
            },
            {
              value: "Kennewick",
              label: "Kennewick"
            },
            {
              value: "La Crosse",
              label: "La Crosse"
            },
            {
              value: "Latah",
              label: "Latah"
            },
            {
              value: "Lewiston",
              label: "Lewiston"
            },
            {
              value: "Mabton",
              label: "Mabton"
            },
            {
              value: "Malden",
              label: "Malden"
            },
            {
              value: "Mattawa",
              label: "Mattawa"
            },
            {
              value: "Mesa",
              label: "Mesa"
            }, {
              value: "Moses Lake",
              label: "Moses Lake"
            },
            {
              value: "Moxee City",
              label: "Moxee City"
            },
            {
              value: "Naches",
              label: "Naches"
            },
            {
              value: "Oakesdale",
              label: "Oakesdale"
            },
            {
              value: "Oroville",
              label: "Oroville"
            },
            {
              value: "Othello",
              label: "Othello"
            },
            {
              value: "Other",
              label: "Other"
            },
            {
              value: "Outlook",
              label: "Outlook"
            },
            {
              value: "Packwood",
              label: "Packwood"
            },
            {
              value: "Palouse",
              label: "Palouse"
            }, {
              value: "Pasco",
              label: "Pasco"
            },
            {
              value: "Paterson",
              label: "Paterson"
            },
            {
              value: "Plymouth",
              label: "Plymouth"
            },
            {
              value: "Pomeroy",
              label: "Pomeroy"
            },
            {
              value: "Prescot",
              label: "Prescot"
            },
            {
              value: "Prosser",
              label: "Prosser"
            },
            {
              value: "Pullman",
              label: "Pullman"
            },
            {
              value: "Republic",
              label: "Republic"
            },
            {
              value: "Richland",
              label: "Richland"
            },
            {
              value: "Ritzville",
              label: "Ritzville"
            }, {
              value: "Rosalia",
              label: "Rosalia"
            },
            {
              value: "Selah",
              label: "Selah"
            },
            {
              value: "Spangle",
              label: "Spangle"
            },
            {
              value: "St. John",
              label: "St. John"
            },
            {
              value: "Steptoe",
              label: "Steptoe"
            },
            {
              value: "Sunnyside",
              label: "Sunnyside"
            },
            {
              value: "Tekoa",
              label: "Tekoa"
            },
            {
              value: "Toppenish",
              label: "Toppenish"
            },
            {
              value: "Touchet",
              label: "Touchet"
            },
            {
              value: "Uniontown",
              label: "Uniontown"
            }, {
              value: "Walla Walla",
              label: "Walla Walla"
            },
            {
              value: "Wallula",
              label: "Wallula"
            },
            {
              value: "Wapato",
              label: "Wapato"
            },
            {
              value: "Warden",
              label: "Warden"
            },
            {
              value: "Washtucna",
              label: "Washtucna"
            },
            {
              value: "West Richland",
              label: "West Richland"
            },
            {
              value: "White Salmon",
              label: "White Salmon"
            },
            {
              value: "Yakima",
              label: "Yakima"
            },
            {
              value: "Zillah",
              label: "Zillah"
            },
          ]}
          showAllEntriesOption={true}
        />
        {rentalPriceFilters(context)}
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        {rentalSearchCheckBoxes(context)}
      </>
    )
  }

  /**
   * Standard filters for global Rental listing search.
   */
  const rentalStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="any"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
        {rentalPriceFilters(context)}
      </>
    )
  }

  /**
   * Sorts to be used in all Rental searches
   */
  const rentalSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (
                (l1.price || l1.priceHigh || 0) - (l0.price || l0.priceHigh || 0)
              )
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (
                (l0.price || l0.priceLow || 0) - (l1.price || l1.priceLow || 0)
              )
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sleeps: High to Low",
            key: "sleepsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sleeps || 0) - (l0.sleeps || 0)
            },
          },
          {
            label: "Sleeps: Low to High",
            key: "sleepsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sleeps || 0) - (l1.sleeps || 0)
            },
          },
        ]}
      />
    )
  }

  /**
   * Communities tabs global filters
   */
  const communitiesTabsGlobalFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "RESIDENTIAL",
              label: "RESIDENTIAL",
            },
            {
              value: "LAND",
              label: "LAND",
            },
            {
              value: "MULTI-FAMILY",
              label: "MULTI-FAMILY",
            },
            {
              value: "FARM & RANCH",
              label: "FARM & RANCH",
            },
          ]}
        />
        {priceFilters(context)}
        <Text
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="text"
          label="MLS#, City, Zip, Area"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
      </>
    )
  }

  const PrevArrow = (props: any) => {
    return (
      <button
        {...props}
        className="absolute top-1/2 z-10 cursor-pointer text-white text-6xl -mt-6 drop-shadow left-0 bg-transparent"
      >
        <svg width="72" height="48" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="22" stroke="white" stroke-width="4" /><path d="M28.1999 10.2L14.3999 24L28.1999 37.8" stroke="white" stroke-width="4" /><path d="M14.9999 23.9999L71.0999 23.9999" stroke="white" stroke-width="4" /></svg>
      </button>
    );
  }

  const NextArrow = (props: any) => {
    return (
      <button
        {...props}
        className="absolute top-1/2 z-10 cursor-pointer text-white text-6xl drop-shadow -mt-6 inline-block right-0 bg-transparent"
      >
        <svg width="72" height="48" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="47.1002" cy="24" r="22" transform="rotate(-180 47.1002 24)" stroke="white" stroke-width="4" /><path d="M42.9003 37.8L56.7003 24L42.9003 10.2" stroke="white" stroke-width="4" /><path d="M56.1003 24.0001L0.000284106 24.0001" stroke="white" stroke-width="4" /></svg>
      </button>
    );
  }

  const LeftArrow = (props: any) => {
    return (
      <button
        {...props}
        className="prev-arrow absolute z-10 cursor-pointer left-0 drop-shadow bg-transparent"
      >
        <span className="block h-full w-full"></span>
      </button>
    );
  }

  const RightArrow = (props: any) => {
    return (
      <button
        {...props}
        className="next-arrow absolute z-10 cursor-pointer right-0 drop-shadow bg-transparent"
      >
        <span className="block h-full w-full"></span>
      </button>
    );
  }

  const locationOrigin = typeof window !== "undefined" ? window.location.origin : "";
  const siteMenuItems: ISiteMenuItem[] = [
    {
      isFolder: true,
      menuText: "Find A Property",
      children: [
        {
          isFolder: false,
          menuText: "Tri Cities Open Houses",
          path: `/find-a-property/tri-cities-open-houses`,
        },
        {
          isFolder: false,
          menuText: "Exclusive Listings",
          path: `/find-a-property/Exclusive-Listings`,
        },
        {
          isFolder: false,
          menuText: "Richland",
          path: `/find-a-property/Richland-Properties`,
        },
        {
          isFolder: false,
          menuText: "West Richland",
          path: `/find-a-property/West-Richland-Properties`,
        },
        {
          isFolder: false,
          menuText: "Kennewick",
          path: `/find-a-property/Kennewick-Properties`,
        },
        {
          isFolder: false,
          menuText: "Pasco Properties",
          path: `/find-a-property/Pasco-Properties`,
        },
        {
          isFolder: false,
          menuText: "Surrounding Area",
          path: `/find-a-property/Surrounding-Area`,
        },
        {
          isFolder: false,
          menuText: "All Tri-Cities Properties",
          path: `/find-a-property/all-tri-cities-properties`,
        },
      ]
    },
    {
      isFolder: true,
      menuText: "Buy",
      children: [
        {
          isFolder: false,
          menuText: "Relocation",
          path: `/buy/relocation`,
        },
        {
          isFolder: false,
          menuText: "First Time Buyers",
          path: `/buy/first-time-buyers`,
        },
      ]
    },
    {
      isFolder: false,
      menuText: "Builders",
      path: "/builders"
    },
    {
      isFolder: true,
      menuText: "Sellers",
      children: [
        {
          isFolder: false,
          menuText: "Sell",
          path: `/sellers/sell`,
        },
        {
          isFolder: false,
          menuText: "Find An Agent",
          path: `/agents`,
        },
      ]
    },
    {
      isFolder: true,
      menuText: "Local Guide",
      children: [
        {
          isFolder: false,
          menuText: "Washington Wine",
          path: `/local-guide/washington-wine`,
        },
        {
          isFolder: false,
          menuText: "Local Guide",
          path: `/local-guide/the-opendoor`,
        },
      ]
    },
    {
      isFolder: false,
      menuText: "Agents",
      path: "/agents"
    },
    {
      isFolder: true,
      menuText: "About",
      children: [
        {
          isFolder: false,
          menuText: "About Us",
          path: `/about/about-Retter-and-co`,
        },
        {
          isFolder: false,
          menuText: "Blog",
          path: `/about/blog`,
        },
        {
          isFolder: false,
          menuText: "Careers",
          path: `/about/Careers`,
        },
        {
          isFolder: false,
          menuText: "Retter & Company Theatre",
          path: `/about/retter-and-company-theatre`,
        },
      ]
    },
  ]

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{        
        listingLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted uppercase",
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            twoLineAddress: true,
            omitAddressParts: ["state", "postal"],
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          summaryProps: {
            className: "non-exlusive max-w-screen-xl mx-auto p-4",
            showFeatures: false,
          },
          mapProps: { title: "", className: "w-99 mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className:
              "bg-zinc-200 flex flex-col items-center px-10 pt-5 pb-14 mt-10",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            layout: EContactFormLayout.column2static,
            invertedForm: false,
            inputTextAreaLabel: "Message...",
            children: (<div></div>),
          },
        },
        exclusiveLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "mb-3 text-center uppercase z-50",
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            twoLineAddress: true
          },
          summaryProps: {
            showFeatures: false,
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
            checkboxGroups: {
              label: "SCHEDULE A PROPERTY TOUR",
              groups: [{
                list: ["In Person Onsite Tour", "Virtual Online Tour"]
              }]
            },
          },
          contactProps: {
            contactCardProps: {
              layout: EContactCardLayout.horizontal
            },
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: { title: "", className: "w-99 mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className: "max-w-screen-xl mx-auto",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto xl:space-x-14",
          showContactUsForm: true,
          contactUsFormProps: {
            layout: EContactFormLayout.column2static,
            invertedForm: false,
            inputTextAreaLabel: "Message...",
            children: (<div></div>),
          },
          galleryCarouselProps: {
            slickSettings: {
              prevArrow: <LeftArrow />,
              nextArrow: <RightArrow />,
              dots: true,
              dotsClass: "slick-dots gallery-pagination-holder",
              appendDots: (dots: any) => {
                return (<div>
                  <ul className="hidden">
                    {dots}
                  </ul>
                </div>)
              },
              customPaging: (i: number) => {
                return (
                  <div className={`hidden gallery-image slider_${i}`}>
                    {`${i + 1 < 10 ? "0" : ""}${i + 1}`}
                  </div>
                )
              },
              onInit: () => {
                const imageCount = document.querySelectorAll(".gallery-image")?.length ?? 0
                const dotsHolder = document.querySelector("div.gallery-pagination-holder")
                const pagination = document.createElement("div")
                pagination.className = "gallery-pagination flex flex-row no-wrap gap-2 text-xl"
                pagination.innerHTML = `Image 1 of ${imageCount}`
                dotsHolder?.appendChild(pagination)
              },
              beforeChange: (current: any, next: any) => {
                const imageCount = document.querySelectorAll(".gallery-image")?.length ?? 0
                const dotsHolder = document.querySelector("div.gallery-pagination-holder")
                const oldPagination = document.querySelector("div.gallery-pagination")
                const pagination = document.createElement("div")
                pagination.className = "gallery-pagination flex flex-row no-wrap gap-2 text-xl"
                pagination.innerHTML = `Image ${next + 1} of ${imageCount}`
                oldPagination ? dotsHolder?.replaceChild(pagination, oldPagination) : dotsHolder?.appendChild(pagination)
              },
              centerPadding: "22%",
              responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    centerPadding: "0px",
                    dots: true,
                  },
                },
              ]
            }
          },
          carouselProps: {

          }
        },
        exclusiveListingLayout: ListingLayoutSingleColumnAlt,
        rentalLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted",
          },
          carouselProps: {
            className: "center",
          },
          detailsProps: {
            title: "Full Details",
            className: "max-w-screen-xl mx-auto",
            layout: EListingDetailsLayout.groupColumns,
          },
          similarCarouselProps: {
            title: "Similar Rentals",
          },
          summaryProps: {
            className: "max-w-screen-xl mx-auto p-4",
            showFeatures: false,
          },
          rowClassName: "max-w-screen-xl mx-auto",
          mapProps: { title: "", className: "my-10" },
          formProps: {
            classNames: "inverted strictTwoColumn property-contact",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me", "Interested in selling or buying a property"] },
                {
                  list: [
                    "Richland",
                    "West Richland",
                    "Kennewick",
                    "Pasco",
                    "Surrounding Areas",
                    "All Tri-Cities Areas",
                  ],
                },
              ],
            },
          },
          calendarProps: {
            displayRates: true
          }
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          alternateFilters: listingMiddleFilters,
          sort: listingSort,

          filterClassName: "inverted w-full",
          headerClassName: "max-w-screen-xl mx-auto w-full",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-full",
          resultsClassName: "max-w-screen-2xl mx-auto",
          className: "flex flex-col items-center w-full"
        },
        listingEntrySearchProps: {
          standardFilters: listingEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          secondaryFilters: listingMiddleFilters,
          filterClassName: "inverted w-full",
          headerClassName: "max-w-screen-xl mx-auto w-full",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-full",
          resultsClassName: "max-w-screen-2xl mx-auto",
          className: "flex flex-col items-center w-full",
          sort: listingSort,
        },
        rentalSearchProps: {
          sort: rentalSort,
          primaryFilters: rentalStandardFilters,
          secondaryFilters: rentalSearchCheckBoxes,
          filterClassName: "inverted text-white",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-99",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        peopleSearchProps: {
          headerClassName: "max-w-screen-lg mx-auto",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          className: "flex flex-col items-center",
          showCount: false,
          pageSize: 12,
          pagerBottom: true
        },
        globalPeopleSearchCardProps: {
          imageWsrvTransform: EWsrvTransform.thumb1x1
        },
        rentalEntrySearchProps: {
          standardFilters: rentalEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        rentalEntryAdvancedSearchProps: {
          standardFilters: rentalEntryAdvancedFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
          onSubmit: async (filters: any) => {
            let featuresFilter: string[] = []
            filters["air"] && featuresFilter.push("A/C")
            filters["waterfront"] && featuresFilter.push("Waterfront")
            filters["linens"] && featuresFilter.push("Linens Provided")
            filters["pool"] && featuresFilter.push("Pool")

            if (featuresFilter.length > 0) {
              filters = {
                ...filters,
                features: featuresFilter,
              }
            }

            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            await navigate(
              data.config.rentalSearchPath +
              "#" +
              params.toString()
            )
          },
        },
        globalContactFormProps: {
          layout: EContactFormLayout.column2static,
          invertedForm: false,
          inputTextAreaLabel: "Message...",
          children: (<div></div>)
        },
        globalSellFormProps: {
          layout: ESellFormLayout.row2map,
          propertyTypes: [
            {
              type: "Residential",
              subtypes: [
                "Single Family Residence",
                "Stock Cooperative",
                "Condominium",
                "Apartment",
                "Townhouse",
                "Mobile Home",
                "Mixed Use",
                "Office",
              ],
            },
            {
              type: "Rental",
              subtypes: [
                "Apartment",
                "Single Family Residence",
                "Condominium",
                "Multi Family",
                "Stock Cooperative",
                "Duplex",
                "Townhouse",
                "Mixed Use",
                "Residential",
                "Mobile Home",
                "Triplex",
                "House (detached)",
                "Office",
                "Condotel",
              ],
            },
            {
              type: "Detached Home",
              subtypes: [],
            },
            {
              type: "Multi-family",
              subtypes: [
                "Multi Family",
                "Duplex",
                "Triplex",
                "Townhouse",
              ],
            },
            {
              type: "Commercial",
              subtypes: [
                "Mixed Use",
                "Office",
                "Retail",
                "Industrial",
                "Warehouse",
                "Apartment",
                "Townhouse",
              ],
            },
            {
              type: "Lots & Land",
              subtypes: ["Unimproved Land"]
            },
            {
              type: "Condo",
              subtypes: [],
            },
            {
              type: "Business Opportunity",
              subtypes: [
                "Retail",
                "Mixed Use",
                "Industrial",
                "Office",
                "Apartment",
                "Warehouse",
              ]
            },
            {
              type: "Co-op",
              subtypes: [],
            },
            {
              type: "Multi-family Townhouse",
              subtypes: [],
            },
            {
              type: "Single-family Townhouse",
              subtypes: [],
            },
            {
              type: "Land",
              subtypes: [],
            },
            {
              type: "House (detached)",
              subtypes: [],
            },
            {
              type: "Vacant Land",
              subtypes: [],
            },
            {
              type: "Condop",
              subtypes: [],
            },
            {
              type: "Commercial Building",
              subtypes: [],
            },
            {
              type: "House (attached)",
              subtypes: [],
            },
            {
              type: "House W/accessory",
              subtypes: [],
            },
          ],
          propertyTypeSelectLabel: "Property Type",
          propertySubtypeSelectLabel: "Sub-Type",
          bedsSelect: {
            placeholder: "Beds",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          bathsSelect: {
            placeholder: "Baths",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          squareFootageSelect: {
            placeholder: "Sq. Ft.",
            selectOptions: [
              {
                label: "Under 1000 ft²",
                value: "Under 1000 ft²",
              },
              {
                label: "1000-2000 ft²",
                value: "1000-2000 ft²",
              },
              {
                label: "2000-3000 ft²",
                value: "2000-3000 ft²",
              },
              {
                label: "3000-4000 ft²",
                value: "3000-4000 ft²",
              },
              {
                label: "4000-5000 ft²",
                value: "4000-5000 ft²",
              },
              {
                label: "5000-6000 ft²",
                value: "5000-6000 ft²",
              },
              {
                label: "6000-7000 ft²",
                value: "6000-7000 ft²",
              },
              {
                label: "7000-8000 ft²",
                value: "7000-8000 ft²",
              },
              {
                label: "Over 8000 ft²",
                value: "Over 8000 ft²",
              },
            ]
          },
          conditionSelect: {
            placeholder: "Condition",
            selectOptions: [
              {
                label: "Excelent",
                value: "Excelent",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Average",
                value: "Average",
              },
              {
                label: "Fair",
                value: "Fair",
              },
              {
                label: "Poor",
                value: "Poor",
              },
            ],
          },
          inputTextAreaLabel: "Additional information...",
        },
        globalListingCarouselProps: {
          slickSettings: {
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            slidesToShow: 1,
          }
        },
        globalListingCarouselCardProps: {
          vitalsTwoLineAddress: false,
          vitalsLocationIcon: true,
          showMlsNumber: true,
          mlsNumberClassNames: "px-[30px] pb-[15px] -mt-[15px]"
        },
        globalListingCardProps: {
          vitalsTwoLineAddress: true,
          vitalsLocationIcon: false,
          displayFlags: true,
          vitalsLayout: EListingVitalsLayout.column1Border0,
          showMlsNumber: true,
          mlsNumberClassNames: "px-[30px] pb-[15px] -mt-[15px]"
        },
        agentLayoutProps: {
          carouselProps: {
            slickSettings: {
              prevArrow: <LeftArrow />,
              nextArrow: <RightArrow />,
              className: "flex flex-row items-center"
            }
          }
        },
        globalCommunitiesContentWithTabsProps: {
          heroCarouselProps: {
            slickSettings: {
              prevArrow: <LeftArrow />,
              nextArrow: <RightArrow />,
              className: "flex flex-row items-center"
            }
          },
          heroCarouselCardProps: {
            maxWidthImage: 1920
          },
          tabsProps: {
            tabListItemClassNames: {
              active: "active"
            }
          },
          communityBladeProps: {
            className: "communities-section",
            columnsWrapperClass: "max-w-screen-2xl border-b border-t border-accent"
          },
          developmentsBladeProps: {
            className: "developments-section",
            columnsWrapperClass: "max-w-screen-2xl"
          },
          listingCarouselProps: {
            className: "slick-center-zoom pt-20 pb-28",
            slickSettings: {
              prevArrow: <LeftArrow />,
              nextArrow: <RightArrow />,
              dots: true,
              appendDots: (dots: any) => (
                <div>
                  <ul className="flex flex-row justify-center items-baseline border-b gap-4">
                    {dots}
                  </ul>
                </div>
              ),
              customPaging: (i: number) => (
                <div className="pb-4">
                  {`${i + 1 < 10 ? "0" : ""}${i + 1}`}
                </div>
              ),
              centerPadding: "22%",
              responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    centerPadding: "0px",
                    dots: true,
                    appendDots: (dots: any) => (<ul>{dots}</ul>),
                    customPaging: (i: number) => (
                      <button>
                        {i + 1}
                      </button>
                    ),
                  },
                },
              ]
            }
          },
          listingCarouselCardsProps: {
            imageProps: {
              lazy: false
            },
            maxWidthImage: 1200,
            vitalsTwoLineAddress: false,
            linkText: "View Property",
            showMlsNumber: true,
            mlsNumberClassNames: "px-[30px] pb-[15px] -mt-[15px] text-center"
          },
          developmentsCarouselProps: {
            prevSlideButtonContent: (
              <svg width="92" height="47" viewBox="0 0 92 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_416_3714" fill="white">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M91.9922 46.4042L45.9952 0.407227L-0.00183051 46.4042L91.9922 46.4042Z" />
                </mask>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M91.9922 46.4042L45.9952 0.407227L-0.00183051 46.4042L91.9922 46.4042Z" fill="#FFFFF8" fill-opacity="0.7" />
                <path d="M45.9952 0.407227L46.7023 -0.29988L45.9952 -1.00699L45.2881 -0.29988L45.9952 0.407227ZM91.9922 46.4042L91.9922 47.4042L94.4064 47.4042L92.6993 45.6971L91.9922 46.4042ZM-0.00183051 46.4042L-0.708938 45.6971L-2.41605 47.4042L-0.00183049 47.4042L-0.00183051 46.4042ZM45.2881 1.11433L91.2851 47.1113L92.6993 45.6971L46.7023 -0.29988L45.2881 1.11433ZM0.705277 47.1113L46.7023 1.11433L45.2881 -0.29988L-0.708938 45.6971L0.705277 47.1113ZM-0.00183049 47.4042L91.9922 47.4042L91.9922 45.4042L-0.00183052 45.4042L-0.00183049 47.4042Z" fill="#C19B5F" mask="url(#path-1-inside-1_416_3714)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.7461 22.2797L46.7461 45.4072L45.2461 45.4072L45.2461 22.2797L38.0536 29.4647L36.9961 28.4072L45.2461 20.1572L45.2461 14.2797L38.0536 21.4647L36.9961 20.4072L45.9961 11.4072L54.9961 20.4072L53.9386 21.4722L46.7461 14.2797L46.7461 20.1572L54.9961 28.4072L53.9386 29.4722L46.7461 22.2797Z" fill="#002349" />
              </svg>
            ),
            nextSlideButtonContent: (
              <svg width="92" height="47" viewBox="0 0 92 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_416_3713" fill="white">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48509e-07 0.410217L45.997 46.4072L91.994 0.410218L5.48509e-07 0.410217Z" />
                </mask>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48509e-07 0.410217L45.997 46.4072L91.994 0.410218L5.48509e-07 0.410217Z" fill="#FFFFF8" fill-opacity="0.7" />
                <path d="M45.997 46.4072L45.2899 47.1143L45.997 47.8214L46.7041 47.1143L45.997 46.4072ZM5.48509e-07 0.410217L5.60434e-07 -0.589783L-2.41421 -0.589783L-0.707106 1.11732L5.48509e-07 0.410217ZM91.994 0.410218L92.7011 1.11733L94.4082 -0.589782L91.994 -0.589782L91.994 0.410218ZM46.7041 45.7001L0.707107 -0.29689L-0.707106 1.11732L45.2899 47.1143L46.7041 45.7001ZM91.2869 -0.296889L45.2899 45.7001L46.7041 47.1143L92.7011 1.11733L91.2869 -0.296889ZM91.994 -0.589782L5.60434e-07 -0.589783L5.36584e-07 1.41022L91.994 1.41022L91.994 -0.589782Z" fill="#C19B5F" mask="url(#path-1-inside-1_416_3713)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.2461 24.5347L45.2461 1.40723L46.7461 1.40723L46.7461 24.5347L53.9386 17.3497L54.9961 18.4072L46.7461 26.6572L46.7461 32.5347L53.9386 25.3497L54.9961 26.4072L45.9961 35.4072L36.9961 26.4072L38.0536 25.3422L45.2461 32.5347L45.2461 26.6572L36.9961 18.4072L38.0536 17.3422L45.2461 24.5347Z" fill="#002349" />
              </svg>
            ),
            //activeSlideIndicator: (<></>)
            activeSlideIndicator: (
              <svg width="133" height="133" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="66.2031" y="131.7" width="92.6263" height="92.6263" transform="rotate(-135 66.2031 131.7)" fill="#FFFFF8" fill-opacity="0.7" stroke="#C19B5F" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M71.4062 66.9531H43.4062V65.4531H71.4062L105.534 65.4531L92.3487 52.2606L93.4062 51.2031L107.656 65.4531H113.534L100.349 52.2606L101.406 51.2031L116.406 66.2031L101.406 81.2031L100.341 80.1456L113.534 66.9531H107.656L93.4062 81.2031L92.3412 80.1456L105.534 66.9531L71.4062 66.9531Z" fill="#002349" />
              </svg>
            )
          },
          baseStandardFilters: communitiesTabsGlobalFilters,
          baseEntrySearchProps: {
            onSubmit: async (filters: any) => {
              const params = new URLSearchParams({
                filters: JSON.stringify(filters),
              })
              await navigate(
                data.config.listingSearchPath + "#" + params.toString() + "&sort=\"priceDESC\"&showMap=true"
              )
            },
            buttonClassName: "bg-accent"
          }
        },
        footerLayout: FooterLayoutLinks,
        footerProps: {
          copyright: `© ${new Date().getFullYear()} Retter & Company Sotheby’s International Realty. All rights reserved.`
        },
        buildersSearchProps: {
          showCount: false,
          headerClassName: "hidden",
          resultsClassName: "max-w-screen-2xl mx-auto lg:gap-y-12 lg:gap-x-32 xl:gap-x-36",
        },
        siteMenuItems: siteMenuItems,
        communityLayoutProps: {
          listingsResultPageSize: 6,
        },
        communityPageLayout: CommunityLayoutHeroBox,        
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}